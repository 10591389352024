














import { Component, Vue } from 'vue-property-decorator'
import { User } from '@/models/User.model'

@Component
export default class Home extends Vue {
  get isAuthenticated(): boolean {
    return this.$store.state.auth.isAuthenticated
  }

  get currentUser(): User | undefined {
    return this.$store.state.auth.currentUser
  }

  created() {
    this.$store.dispatch('auth/checkIsAuthenticated')
  }
}
